import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.095 2.013c.589-1.35 2.504-1.35 3.093 0 .157.36.584.515.935.34 1.32-.656 2.787.575 2.37 1.988a.687.687 0 0 0 .498.863c1.432.345 1.764 2.231.537 3.045a.687.687 0 0 0-.173.981c.875 1.185-.083 2.844-1.547 2.678a.687.687 0 0 0-.762.64c-.092 1.47-1.892 2.126-2.907 1.058a.687.687 0 0 0-.995 0c-1.016 1.068-2.815.413-2.907-1.058a.687.687 0 0 0-.762-.64c-1.464.166-2.422-1.493-1.547-2.678a.687.687 0 0 0-.173-.98C.528 7.434.86 5.548 2.292 5.203a.687.687 0 0 0 .498-.863c-.417-1.413 1.05-2.644 2.37-1.988.351.175.778.02.935-.34zm2.176.4a.687.687 0 0 0-1.26 0 1.687 1.687 0 0 1-2.297.836.687.687 0 0 0-.965.81 1.687 1.687 0 0 1-1.222 2.117.687.687 0 0 0-.219 1.24c.804.534.998 1.632.425 2.408a.687.687 0 0 0 .63 1.09 1.687 1.687 0 0 1 1.872 1.572.687.687 0 0 0 1.184.431c.665-.7 1.78-.7 2.445 0a.687.687 0 0 0 1.183-.43c.06-.964.914-1.68 1.873-1.572a.687.687 0 0 0 .63-1.091 1.687 1.687 0 0 1 .425-2.408.687.687 0 0 0-.219-1.24 1.687 1.687 0 0 1-1.222-2.118.687.687 0 0 0-.965-.81 1.687 1.687 0 0 1-2.298-.836z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.641 6.217a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEdit;
