import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M2.833 8a.5.5 0 0 1 .5-.5h9.334a.5.5 0 1 1 0 1H3.333a.5.5 0 0 1-.5-.5" clipRule="evenodd" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.98 7.647a.5.5 0 0 1 .707 0l2.667 2.666a.5.5 0 1 1-.708.707L2.98 8.354a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.354 4.98a.5.5 0 0 1 0 .707L3.687 8.354a.5.5 0 0 1-.707-.707L5.646 4.98a.5.5 0 0 1 .708 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowLeft;
