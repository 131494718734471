import * as React from 'react';
import type { SVGProps } from 'react';
const SvgStater = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 92 92" width="1em" height="1em" {...props}>
    <g filter="url(#stater_svg__a)">
      <rect width={80} height={80} x={6} y={4} fill="#fff" rx={4} />
    </g>
    <mask id="stater_svg__b" width={80} height={80} x={6} y={4} mask-type="alpha" maskUnits="userSpaceOnUse">
      <rect width={80} height={80} x={6} y={4} fill="#fff" rx={4} />
    </mask>
    <g mask="url(#stater_svg__b)">
      <path
        fill="#572179"
        d="m43.655 15.374-26.11 26.16 5.313 5.214S42.123 27.51 46.356 23.195c4.232 4.315 23.499 23.553 23.499 23.553l5.311-5.214-28.9-28.767z"
      />
      <path
        fill="#F59C00"
        d="M55.54 63.02a9.253 9.253 0 0 1-9.274 9.26 9.253 9.253 0 0 1-9.273-9.26 9.253 9.253 0 0 1 9.273-9.26 9.253 9.253 0 0 1 9.273 9.26z"
      />
      <path stroke="#572179" d="M-113.452 62.75H17.456m57.62 0h212.568H75.077z" />
    </g>
    <defs>
      <filter id="stater_svg__a" width={92} height={92} x={0} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={2} />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);
export default SvgStater;
