import * as React from 'react';
import type { SVGProps } from 'react';
const SvgError = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11M1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.313 6.313a.5.5 0 0 1 .707 0l.98.98.98-.98a.5.5 0 0 1 .707.707l-.98.98.98.98a.5.5 0 0 1-.707.707L8 8.707l-.98.98a.5.5 0 0 1-.707-.707l.98-.98-.98-.98a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgError;
