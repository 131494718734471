import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMegaphone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.833}
      d="M11.875 6.25v9.665a.62.62 0 0 0 .278.52l.86.573a.625.625 0 0 0 .952-.368L15 12.5"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.833}
      d="M15 12.5a3.125 3.125 0 0 0 0-6.25h-3.125s-4.254 0-8.348-3.434a.625.625 0 0 0-1.027.478v12.162a.625.625 0 0 0 1.027.477C7.621 12.5 11.875 12.5 11.875 12.5z"
    />
  </svg>
);
export default SvgMegaphone;
