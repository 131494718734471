import * as React from 'react';
import type { SVGProps } from 'react';
const SvgProducts = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 3.167a.167.167 0 0 0-.166.166V6c0 .092.074.167.166.167H6A.167.167 0 0 0 6.167 6V3.333A.167.167 0 0 0 6 3.166zm-1.166.166c0-.644.522-1.167 1.166-1.167H6c.644 0 1.167.523 1.167 1.167V6c0 .644-.523 1.167-1.167 1.167H3.333A1.167 1.167 0 0 1 2.167 6zm1.166 6.5a.167.167 0 0 0-.166.167v2.667c0 .092.074.166.166.166H6a.167.167 0 0 0 .167-.166V10A.167.167 0 0 0 6 9.833zM2.167 10c0-.644.522-1.167 1.166-1.167H6c.644 0 1.167.523 1.167 1.167v2.667c0 .644-.523 1.166-1.167 1.166H3.333a1.167 1.167 0 0 1-1.166-1.166zM10 9.833a.167.167 0 0 0-.167.167v2.667c0 .092.075.166.167.166h2.667a.167.167 0 0 0 .166-.166V10a.167.167 0 0 0-.166-.167zM8.833 10c0-.644.523-1.167 1.167-1.167h2.667c.644 0 1.166.523 1.166 1.167v2.667c0 .644-.522 1.166-1.166 1.166H10a1.167 1.167 0 0 1-1.167-1.166zm0-5.333a.5.5 0 0 1 .5-.5h4a.5.5 0 1 1 0 1h-4a.5.5 0 0 1-.5-.5"
      clipRule="evenodd"
    />
    <path fill="currentColor" fillRule="evenodd" d="M11.333 2.167a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0v-4a.5.5 0 0 1 .5-.5" clipRule="evenodd" />
  </svg>
);
export default SvgProducts;
