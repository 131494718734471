import * as React from 'react';
import type { SVGProps } from 'react';
const SvgQuestion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11M1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M7.245 9.703a.11.11 0 0 1-.109-.11q.003-.562.1-.897a1.34 1.34 0 0 1 .29-.54q.189-.206.456-.373.2-.129.357-.267a1.2 1.2 0 0 0 .251-.306.8.8 0 0 0 .093-.38.7.7 0 0 0-.106-.389.7.7 0 0 0-.286-.257.85.85 0 0 0-.393-.09.9.9 0 0 0-.395.093.76.76 0 0 0-.306.27c-.145.216-.334.441-.594.441h-.17c-.371 0-.687-.314-.567-.665a1.6 1.6 0 0 1 .199-.397q.292-.42.775-.627A2.65 2.65 0 0 1 7.905 5q.64 0 1.132.212.492.21.772.608.28.4.28.962 0 .376-.125.67a1.6 1.6 0 0 1-.345.514 2.7 2.7 0 0 1-.524.402q-.255.15-.418.315a1.1 1.1 0 0 0-.241.38 1.6 1.6 0 0 0-.08.53.11.11 0 0 1-.11.11h-1Zm.528 2.059a.76.76 0 0 1-.55-.225A.74.74 0 0 1 7 10.99a.73.73 0 0 1 .222-.54.76.76 0 0 1 .55-.226q.306 0 .537.225a.74.74 0 0 1 .122.93.8.8 0 0 1-.28.28.73.73 0 0 1-.379.103Z"
    />
  </svg>
);
export default SvgQuestion;
