import * as React from 'react';
import { LoaderIcon } from './icons';
import { styled } from '@styles/stitches.config';
import { spin } from '@styles/animation';

type IconSize = 'md' | 'lg';

const ICON_SIZES: Record<IconSize, number> = {
  md: 24,
  lg: 32,
};

const StyledLoaderIcon = styled(LoaderIcon, {
  animationName: spin.toString(),
  animationDuration: '2s',
  animationTimingFunction: 'linear',
  animationIterationCount: 'infinite',
});

export function Loader({ size = 'md' }: { size?: IconSize }): JSX.Element {
  return <StyledLoaderIcon width={ICON_SIZES[size]} height={ICON_SIZES[size]} />;
}
