import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationActive = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 17" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.822 3.155a1.667 1.667 0 0 1 2.831.965 5 5 0 0 1 2.68 4.213v1.98a2.33 2.33 0 0 0 1.165 1.73.333.333 0 0 1-.165.623H2.667a.333.333 0 0 1-.165-.623 2.33 2.33 0 0 0 1.165-1.73V8.317a5 5 0 0 1 2.68-4.198c.047-.363.213-.703.474-.965z"
      clipRule="evenodd"
    />
    <circle cx={12.5} cy={3.5} r={3.5} fill="#F59C00" />
    <path fill="#fff" fillRule="evenodd" d="M12.5 6a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m0 1a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7" clipRule="evenodd" />
    <path fill="currentColor" d="M10.5 13a2.5 2.5 0 0 1-5 0z" />
  </svg>
);
export default SvgNotificationActive;
