import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a2.167 2.167 0 1 0 0 4.333A2.167 2.167 0 0 0 8 2.5M4.833 4.667a3.167 3.167 0 1 1 6.334 0 3.167 3.167 0 0 1-6.334 0M6.667 10.5A2.167 2.167 0 0 0 4.5 12.667V14a.5.5 0 0 1-1 0v-1.333A3.167 3.167 0 0 1 6.667 9.5h2.666a3.167 3.167 0 0 1 3.167 3.167V14a.5.5 0 0 1-1 0v-1.333A2.167 2.167 0 0 0 9.333 10.5z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUser;
