import { styled } from '@styles/stitches.config';

export const OrderedList = styled('ol', {
  counterReset: 'ordered-list',
  listStylePosition: 'outside',
  listStyleType: 'none',
  my: '$6',
  spaceY: '$2',

  '@md': {
    spaceY: '$4',
  },
  '@lg': {
    my: '$8',
    spaceY: '$6',
  },

  ['> li > *']: {
    position: 'relative',
  },

  ['> li > *::before']: {
    counterIncrement: 'ordered-list',
    content: "counters(ordered-list, '', decimal) ' '",
    position: 'absolute',
    height: '100%',
    color: '$black',
    marginLeft: '-1.65rem',
  },
});
