import * as React from 'react';
import type { SVGProps } from 'react';
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.687 4.347a.5.5 0 0 1 0 .707l-6.6 6.6a.5.5 0 1 1-.707-.708l6.6-6.6a.5.5 0 0 1 .707 0z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.313 4.347a.5.5 0 0 1 .707 0l6.6 6.6a.5.5 0 0 1-.707.707l-6.6-6.6a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgClose;
