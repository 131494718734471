import * as React from 'react';
import type { SVGProps } from 'react';
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.11 4.11A5.5 5.5 0 0 1 15 2.5h3a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-3a.5.5 0 0 0-.5.5v1.5H18a.5.5 0 0 1 .485.621l-1 4A.5.5 0 0 1 17 14.5h-2.5V21a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-6.5H7a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h2.5V8a5.5 5.5 0 0 1 1.61-3.89M15 3.5A4.5 4.5 0 0 0 10.5 8v2a.5.5 0 0 1-.5.5H7.5v3H10a.5.5 0 0 1 .5.5v6.5h3V14a.5.5 0 0 1 .5-.5h2.61l.75-3H14a.5.5 0 0 1-.5-.5V8A1.5 1.5 0 0 1 15 6.5h2.5v-3z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgFacebook;
