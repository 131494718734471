import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDownload = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.667 10.834a.5.5 0 0 1 .5.5v1.333A.833.833 0 0 0 4 13.5h8a.833.833 0 0 0 .833-.833v-1.333a.5.5 0 1 1 1 0v1.333A1.833 1.833 0 0 1 12 14.5H4a1.833 1.833 0 0 1-1.833-1.833v-1.333a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.313 6.98a.5.5 0 0 1 .707 0L8 9.96l2.98-2.98a.5.5 0 1 1 .707.707L8.354 11.02a.5.5 0 0 1-.708 0L4.313 7.687a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
    <path fill="currentColor" fillRule="evenodd" d="M8 2.167a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5" clipRule="evenodd" />
  </svg>
);
export default SvgDownload;
