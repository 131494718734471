import { styled } from '@styles/stitches.config';

export const UnorderedList = styled('ul', {
  listStylePosition: 'outside',
  my: '$6',
  spaceY: '$2',

  '@md': {
    spaceY: '$4',
  },
  '@lg': {
    my: '$8',
    spaceY: '$6',
  },

  ['> li > *']: {
    position: 'relative',
  },
});
