import * as React from 'react';
import type { SVGProps } from 'react';
const SvgExcel = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 22" width="1em" height="1em" {...props}>
    <path
      fill="#006647"
      fillRule="evenodd"
      d="M1.044 2.1A1.25 1.25 0 0 0 0 3.335v15.382a1.25 1.25 0 0 0 1.044 1.233l12.5 2.083A1.25 1.25 0 0 0 15 20.8V1.25A1.25 1.25 0 0 0 13.544.017zM6.75 14.85h-3v-.975h.825l2.275-3.488-2.237-3.4H3.8v-.962h3.075v.962h-.938L7.4 9.3c.137.187.225.35.225.35h.025s.075-.163.212-.35l1.413-2.313h-.912v-.962h2.987v.962h-.825L8.238 10.5l2.25 3.375h.837v.975H8.25v-.975h.925l-1.475-2.3a4 4 0 0 1-.225-.363H7.45q-.008.002-.041.074c-.033.066-.085.17-.159.289l-1.425 2.3h.925z"
      clipRule="evenodd"
    />
    <path
      fill="#006647"
      fillRule="evenodd"
      d="M16.25 20.75h8.25c.69 0 1.25-.56 1.25-1.25V3.25c0-.69-.56-1.25-1.25-1.25h-8.25v2.5H18V7h-1.75v1.25H18v2.5h-1.75V12H18v2.5h-1.75v1.25H18v2.5h-1.75zm-1.25-5h-.75v2.5H15v2.5h-3V2h3v2.5h-.75V7H15v1.25h-.75v2.5H15V12h-.75v2.5H15zM23 4.5h-3.75V7H23zm0 3.75h-3.75v2.5H23zM23 12h-3.75v2.5H23zm0 3.75h-3.75v2.5H23z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgExcel;
