export { default as AdjustIcon } from './adjust';
export { default as ArrowDownIcon } from './arrow-down';
export { default as ArrowLeftIcon } from './arrow-left';
export { default as ArrowRightIcon } from './arrow-right';
export { default as ArrowUpIcon } from './arrow-up';
export { default as BookIcon } from './book';
export { default as CalendarIcon } from './calendar';
export { default as ChatIcon } from './chat';
export { default as CheckmarkIcon } from './checkmark';
export { default as ChevronDownIcon } from './chevron-down';
export { default as ChevronLeftIcon } from './chevron-left';
export { default as ChevronRightIcon } from './chevron-right';
export { default as ChevronUpIcon } from './chevron-up';
export { default as CloseIcon } from './close';
export { default as DashboardIcon } from './dashboard';
export { default as DownloadIcon } from './download';
export { default as EditIcon } from './edit';
export { default as ErrorRedIcon } from './error-red';
export { default as ErrorIcon } from './error';
export { default as ExcelIcon } from './excel';
export { default as EyeOffIcon } from './eye-off';
export { default as EyeIcon } from './eye';
export { default as FacebookIcon } from './facebook';
export { default as HamburgerIcon } from './hamburger';
export { default as HelpcenterIcon } from './helpcenter';
export { default as HomeIcon } from './home';
export { default as InfoIcon } from './info';
export { default as InstagramIcon } from './instagram';
export { default as LinkedinIcon } from './linkedin';
export { default as LoaderIcon } from './loader';
export { default as MailIcon } from './mail';
export { default as MapPointerIcon } from './map-pointer';
export { default as MegaphoneIcon } from './megaphone';
export { default as MinusCircleIcon } from './minus-circle';
export { default as MinusIcon } from './minus';
export { default as NotificationActiveIcon } from './notification-active';
export { default as NotificationDefaultIcon } from './notification-default';
export { default as OptionsIcon } from './options';
export { default as PdfIcon } from './pdf';
export { default as PencilIcon } from './pencil';
export { default as PhoneIcon } from './phone';
export { default as PlusCircleIcon } from './plus-circle';
export { default as PlusIcon } from './plus';
export { default as PowerpointIcon } from './powerpoint';
export { default as ProductsIcon } from './products';
export { default as QuestionIcon } from './question';
export { default as SearchIcon } from './search';
export { default as StaterLogoIcon } from './stater-logo';
export { default as StaterIcon } from './stater';
export { default as SuccessCircleIcon } from './success-circle';
export { default as SuccessGreenIcon } from './success-green';
export { default as TicketsIcon } from './tickets';
export { default as TrashcanIcon } from './trashcan';
export { default as UserIcon } from './user';
export { default as UsersIcon } from './users';
export { default as WarningIcon } from './warning';
export { default as WordIcon } from './word';
export { default as YoutubeIcon } from './youtube';
