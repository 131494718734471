import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 6.5a.5.5 0 0 1 .5.5v1.333a.5.5 0 0 1-1 0V7a.5.5 0 0 1 .5-.5m0 4a.5.5 0 0 1 .5.5v.007a.5.5 0 0 1-1 0V11a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.062 2.733a1.833 1.833 0 0 1 2.533.687l4.73 8.163q.019.03.032.063a1.834 1.834 0 0 1-1.687 2.52H3.234a1.834 1.834 0 0 1-1.604-2.52 1 1 0 0 1 .03-.063L6.393 3.42c.158-.284.39-.522.67-.687zM3.297 13.168l.036-.001h9.332a.834.834 0 0 0 .776-1.117L8.722 3.91a.833.833 0 0 0-1.458 0l-.005.009-4.714 8.133a.833.833 0 0 0 .752 1.117z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWarning;
