import { styled } from '@styles/stitches.config';

const DEFAULT_TAG = 'span';

export type TextProps = React.ComponentProps<typeof Text>;

export const Text = styled(DEFAULT_TAG, {
  fontFamily: '$body',
  // Adaptive line height, source: https://kittygiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height/
  lineHeight: 'calc(2px + 2.2ex + 2px)',
  variants: {
    variant: {
      default: {
        fontSize: '$sm',
        '@xl': {
          fontSize: '$lg',
        },
      },
      xsmall: {
        fontSize: '$xxs',
        '@xl': {
          fontSize: '$xs',
        },
      },
      small: {
        fontSize: '$xs',
        '@xl': {
          fontSize: '$sm',
        },
      },
      intro: {
        fontWeight: 'medium',
        fontSize: '1.375rem',
      },
      label: {
        fontWeight: 'bold',
        fontSize: '$xs',
        '@xl': {
          fontSize: '$sm',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});
