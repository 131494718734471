import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1 3.5c0 .276.209.5.467.5h13.066c.258 0 .467-.224.467-.5s-.209-.5-.467-.5H1.467C1.209 3 1 3.224 1 3.5M1 8c0 .276.209.5.467.5H10.8c.258 0 .467-.224.467-.5s-.21-.5-.467-.5H1.467C1.209 7.5 1 7.724 1 8m0 4.5c0 .276.209.5.467.5h5.6c.257 0 .466-.224.466-.5s-.209-.5-.466-.5h-5.6c-.258 0-.467.224-.467.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHamburger;
