import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6A.5.5 0 0 1 14 8.5h-1.333a.5.5 0 1 1 0-1h.126L8 2.707 3.207 7.5h.126a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.354-.854z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 7.5a.5.5 0 0 1 .5.5v4.667a.833.833 0 0 0 .834.833v1a1.833 1.833 0 0 1-1.834-1.833V8a.5.5 0 0 1 .5-.5m1.334 7H6a.5.5 0 0 0 0-1H4.667zm8-7a.5.5 0 0 1 .5.5v4.667a1.833 1.833 0 0 1-1.834 1.833H10a.5.5 0 0 1 0-1h1.333a.833.833 0 0 0 .834-.833V8a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.333 9.167A.833.833 0 0 0 6.5 10v4a.5.5 0 0 1-1 0v-4a1.833 1.833 0 0 1 1.833-1.833h1.334A1.833 1.833 0 0 1 10.5 10v4a.5.5 0 1 1-1 0v-4a.833.833 0 0 0-.833-.833z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHome;
