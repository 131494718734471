import * as React from 'react';
import type { SVGProps } from 'react';
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 3.833a.833.833 0 0 0-.833.834v8c0 .46.373.833.833.833h8c.46 0 .833-.373.833-.833v-8A.833.833 0 0 0 12 3.833zm-1.833.834c0-1.013.82-1.834 1.833-1.834h8c1.012 0 1.833.821 1.833 1.834v8c0 1.012-.82 1.833-1.833 1.833H4a1.833 1.833 0 0 1-1.833-1.833z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.667 1.5a.5.5 0 0 1 .5.5v2.667a.5.5 0 0 1-1 0V2a.5.5 0 0 1 .5-.5m-5.334 0a.5.5 0 0 1 .5.5v2.667a.5.5 0 1 1-1 0V2a.5.5 0 0 1 .5-.5M2.167 7.333a.5.5 0 0 1 .5-.5h10.666a.5.5 0 0 1 0 1H2.667a.5.5 0 0 1-.5-.5M6.833 10a.5.5 0 0 1 .5-.5H8a.5.5 0 0 1 0 1h-.667a.5.5 0 0 1-.5-.5"
      clipRule="evenodd"
    />
    <path fill="currentColor" fillRule="evenodd" d="M8 9.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5" clipRule="evenodd" />
  </svg>
);
export default SvgCalendar;
