import * as React from 'react';
import type { SVGProps } from 'react';
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2.5a2.167 2.167 0 1 0 0 4.333A2.167 2.167 0 0 0 6 2.5M2.833 4.667a3.167 3.167 0 1 1 6.334 0 3.167 3.167 0 0 1-6.334 0M4.667 10.5A2.167 2.167 0 0 0 2.5 12.667V14a.5.5 0 0 1-1 0v-1.333A3.167 3.167 0 0 1 4.667 9.5h2.666a3.167 3.167 0 0 1 3.167 3.167V14a.5.5 0 0 1-1 0v-1.333A2.167 2.167 0 0 0 7.333 10.5zm5.515-8.537a.5.5 0 0 1 .609-.36 3.167 3.167 0 0 1 0 6.135.5.5 0 1 1-.248-.969 2.167 2.167 0 0 0 0-4.198.5.5 0 0 1-.36-.608zm1.334 8.012a.5.5 0 0 1 .609-.36 3.17 3.17 0 0 1 2.375 3.049V14a.5.5 0 0 1-1 0v-1.332a2.166 2.166 0 0 0-1.625-2.084.5.5 0 0 1-.36-.609z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgUsers;
