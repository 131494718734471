import { keyframes } from './stitches.config';

export const spin = keyframes({
  '100%': { transform: 'rotate(1turn)' },
});

export const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

export const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
});
