import { DEFAULT_LOCALE, DEFAULT_LOCALE_NAME } from '@src/constants';

export function pageTitle(title: string): string {
  return `${title} - Stater`;
}

const allLocales = ['nl-NL', 'de-DE', 'en'] as const;

export type ContentfulLocales = (typeof allLocales)[number];

function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
  return coll.includes(el as T);
}

export function ensureContentfulLocale(locale?: string): ContentfulLocales {
  if (includes(allLocales, locale)) return locale;

  return DEFAULT_LOCALE;
}

export function transformLocalesLong(locale: string): ContentfulLocales {
  switch (locale) {
    case 'nl':
      return 'nl-NL';
    case 'de':
      return 'de-DE';
    default:
      return DEFAULT_LOCALE;
  }
}

export function transformLocalesShort(locale: ContentfulLocales): string {
  switch (locale) {
    case 'nl-NL':
      return 'nl';
    case 'de-DE':
      return 'de';
    default:
      return DEFAULT_LOCALE;
  }
}

export function transformLocalesName(locale: string): string {
  switch (locale) {
    case 'nl-NL':
      return 'Dutch (Nederlands)';
    case 'de-DE':
      return 'German (Deutsch)';
    default:
      return DEFAULT_LOCALE_NAME;
  }
}
