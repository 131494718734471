import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 3.167a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.166.166a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M8 3.167A.167.167 0 1 0 8 3.5a.167.167 0 0 0 0-.333m-1.167.166a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m5.834-.166a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.167.166a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0m-8.167 4.5a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M2.167 8A1.167 1.167 0 1 1 4.5 8a1.167 1.167 0 0 1-2.333 0M8 7.833a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M6.833 8a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m5.834-.167a.167.167 0 1 0 0 .334.167.167 0 0 0 0-.334M11.5 8a1.167 1.167 0 1 1 2.333 0A1.167 1.167 0 0 1 11.5 8m-8.167 4.5a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.166.166a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0M8 12.5a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.167.166a1.167 1.167 0 1 1 2.334 0 1.167 1.167 0 0 1-2.334 0m5.834-.166a.167.167 0 1 0 0 .333.167.167 0 0 0 0-.333m-1.167.166a1.167 1.167 0 1 1 2.333 0 1.167 1.167 0 0 1-2.333 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgDashboard;
