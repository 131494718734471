import * as React from 'react';
import type { SVGProps } from 'react';
const SvgNotificationDefault = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a.833.833 0 0 0-.833.825.5.5 0 0 1-.296.465A4.17 4.17 0 0 0 4.5 7.345v1.988q0 .03-.004.06a3.17 3.17 0 0 1-.552 1.44h8.112a3.17 3.17 0 0 1-.553-1.44l-.003-.06V7.345a4.17 4.17 0 0 0-2.38-3.56.5.5 0 0 1-.287-.452A.833.833 0 0 0 8 2.5m-1.805.512a1.833 1.833 0 0 1 3.61 0A5.17 5.17 0 0 1 12.5 7.333v1.969a2.17 2.17 0 0 0 1.08 1.596.5.5 0 0 1-.247.935H2.667a.5.5 0 0 1-.247-.935A2.17 2.17 0 0 0 3.5 9.302V7.31a5.17 5.17 0 0 1 2.695-4.298"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 10.833a.5.5 0 0 1 .5.5V12a1.5 1.5 0 0 0 3 0v-.667a.5.5 0 0 1 1 0V12a2.5 2.5 0 0 1-5 0v-.667a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgNotificationDefault;
