import * as React from 'react';
import type { SVGProps } from 'react';
const SvgSuccessGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path fill="#006647" fillRule="evenodd" d="M1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0" clipRule="evenodd" />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M10.354 6.313a.5.5 0 0 1 0 .707L7.687 9.687a.5.5 0 0 1-.707 0L5.646 8.353a.5.5 0 1 1 .708-.707l.98.98 2.312-2.313a.5.5 0 0 1 .708 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSuccessGreen;
