import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M8 2.833a.5.5 0 0 1 .5.5v9.334a.5.5 0 1 1-1 0V3.333a.5.5 0 0 1 .5-.5" clipRule="evenodd" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.646 2.98a.5.5 0 0 1 .708 0l2.666 2.667a.5.5 0 1 1-.707.707L7.646 3.687a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.354 2.98a.5.5 0 0 1 0 .707L5.687 6.354a.5.5 0 0 1-.707-.707L7.646 2.98a.5.5 0 0 1 .708 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowUp;
