import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPhone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 3.167a.833.833 0 0 0-.833.82 10.167 10.167 0 0 0 9.514 9.513.833.833 0 0 0 .82-.833v-2.329L10.21 9.29l-.781 1.302a.5.5 0 0 1-.65.19 7.83 7.83 0 0 1-3.56-3.56.5.5 0 0 1 .19-.65l1.302-.78-1.05-2.624zm-1.296-.463c.344-.344.81-.537 1.296-.537H6a.5.5 0 0 1 .464.314l1.334 3.333a.5.5 0 0 1-.207.615l-1.259.755a6.83 6.83 0 0 0 2.484 2.484l.755-1.259a.5.5 0 0 1 .615-.207l3.333 1.334a.5.5 0 0 1 .314.464v2.667a1.833 1.833 0 0 1-1.863 1.832A11.167 11.167 0 0 1 1.5 4c0-.486.193-.953.537-1.296M9.5 4.667a.5.5 0 0 1 .5-.5A1.834 1.834 0 0 1 11.833 6a.5.5 0 1 1-1 0A.834.834 0 0 0 10 5.167a.5.5 0 0 1-.5-.5M9.5 2a.5.5 0 0 1 .5-.5A4.5 4.5 0 0 1 14.5 6a.5.5 0 0 1-1 0A3.5 3.5 0 0 0 10 2.5a.5.5 0 0 1-.5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPhone;
