import * as React from 'react';
import type { SVGProps } from 'react';
const SvgLoader = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V2a.5.5 0 0 1 .5-.5m4.62 1.88a.5.5 0 0 1 0 .707L11.187 5.52a.5.5 0 1 1-.707-.707l1.433-1.433a.5.5 0 0 1 .707 0M11.5 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5m-1.02 2.48a.5.5 0 0 1 .707 0l1.433 1.433a.5.5 0 1 1-.707.707l-1.433-1.433a.5.5 0 0 1 0-.707M8 11.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5m-2.48-1.02a.5.5 0 0 1 0 .707L4.087 12.62a.5.5 0 1 1-.707-.707l1.433-1.433a.5.5 0 0 1 .707 0M1.5 8a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1H2a.5.5 0 0 1-.5-.5m1.88-4.62a.5.5 0 0 1 .707 0L5.52 4.813a.5.5 0 1 1-.707.707L3.38 4.087a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgLoader;
