import * as React from 'react';
import type { SVGProps } from 'react';
const SvgWord = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 22" width="1em" height="1em" {...props}>
    <path
      fill="#007BC2"
      fillRule="evenodd"
      d="M16.24 21.024h8.51c.69 0 1.25-.56 1.25-1.25V3.524c0-.69-.56-1.25-1.25-1.25h-8.5v2.5h7.25v1.25h-7.25v1.25h7.25v1.25h-7.25v1.25h7.25v1.25h-7.25v1.25h7.25v1.25h-7.25v1.25h7.25v1.25h-7.25v1.25h7.25v1.25h-7.25V20.8q0 .114-.01.225ZM15 17.274h-.25v1.25H15V20.8q0 .116-.02.225h-2.73V2.274H15v2.5h-.25v1.25H15v1.25h-.25v1.25H15v1.25h-.25v1.25H15v1.25h-.25v1.25H15v1.25h-.25v1.25H15z"
      clipRule="evenodd"
    />
    <path
      fill="#007BC2"
      fillRule="evenodd"
      d="M1.044 2.1A1.25 1.25 0 0 0 0 3.335v15.382a1.25 1.25 0 0 0 1.044 1.233l12.5 2.083A1.25 1.25 0 0 0 15 20.8V1.25A1.25 1.25 0 0 0 13.544.017zm2.214 7.421-.787.01-.013-.937 2.825-.037.013.937-.85.011 1.123 3.66c.079.262.108.587.108.587h.037s.021-.326.093-.59l1.326-4.617 1.1-.015 1.448 4.582c.079.261.108.586.108.586h.038s.02-.326.092-.59l1.039-3.689-.863.012-.012-.938 2.825-.037.012.937-.787.01-1.554 5.41-1.35.017-1.393-4.207a5 5 0 0 1-.12-.598H7.69s-.033.313-.104.601L6.304 14.87l-1.35.018L3.258 9.52Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgWord;
