import * as React from 'react';
import type { SVGProps } from 'react';
const SvgAdjust = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 5.833A.833.833 0 1 0 4 7.5a.833.833 0 0 0 0-1.667m-1.833.833a1.833 1.833 0 1 1 3.666 0 1.833 1.833 0 0 1-3.666 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4 2.167a.5.5 0 0 1 .5.5v2.666a.5.5 0 0 1-1 0V2.666a.5.5 0 0 1 .5-.5zM4 7.5a.5.5 0 0 1 .5.5v5.333a.5.5 0 0 1-1 0V8a.5.5 0 0 1 .5-.5m4 2.333A.833.833 0 1 0 8 11.5a.833.833 0 0 0 0-1.667m-1.833.833a1.833 1.833 0 1 1 3.666 0 1.833 1.833 0 0 1-3.666 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.167a.5.5 0 0 1 .5.5v6.666a.5.5 0 0 1-1 0V2.666a.5.5 0 0 1 .5-.5zM8 11.5a.5.5 0 0 1 .5.5v1.333a.5.5 0 0 1-1 0V12a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgAdjust;
