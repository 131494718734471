import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.333 3.833a.833.833 0 0 0-.833.834v6.667c0 .46.373.833.833.833h9.334c.46 0 .833-.373.833-.833V4.667a.833.833 0 0 0-.833-.833zM1.5 4.667c0-1.013.82-1.833 1.833-1.833h9.334c1.012 0 1.833.82 1.833 1.833v6.667c0 1.012-.82 1.833-1.833 1.833H3.333A1.833 1.833 0 0 1 1.5 11.334z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.584 4.39a.5.5 0 0 1 .693-.14L8 8.067l5.723-3.815a.5.5 0 0 1 .554.832l-6 4a.5.5 0 0 1-.554 0l-6-4a.5.5 0 0 1-.139-.694"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMail;
