import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTrashcan = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.167 4.667a.5.5 0 0 1 .5-.5h10.666a.5.5 0 0 1 0 1H2.667a.5.5 0 0 1-.5-.5m4.5 2.166a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0v-4a.5.5 0 0 1 .5-.5m2.666 0a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0v-4a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.292 4.168a.5.5 0 0 1 .54.457l.666 8 .002.042a.833.833 0 0 0 .833.833h5.334a.833.833 0 0 0 .833-.833q0-.021.002-.042l.666-8a.5.5 0 1 1 .997.083L12.5 12.69a1.833 1.833 0 0 1-1.833 1.811H5.333A1.833 1.833 0 0 1 3.5 12.69l-.665-7.982a.5.5 0 0 1 .457-.54"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.667 2.5a.167.167 0 0 0-.167.167v2a.5.5 0 0 1-1 0v-2A1.167 1.167 0 0 1 6.667 1.5h2.666A1.167 1.167 0 0 1 10.5 2.667v2a.5.5 0 1 1-1 0v-2a.167.167 0 0 0-.167-.167z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTrashcan;
