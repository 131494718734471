import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChevronUp = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.646 5.646a.5.5 0 0 1 .708 0l4 4a.5.5 0 0 1-.708.708L8 6.707l-3.646 3.647a.5.5 0 0 1-.708-.708z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChevronUp;
