import * as React from 'react';
import type { SVGProps } from 'react';
const SvgArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path fill="currentColor" fillRule="evenodd" d="M2.833 8a.5.5 0 0 1 .5-.5h9.334a.5.5 0 0 1 0 1H3.333a.5.5 0 0 1-.5-.5" clipRule="evenodd" />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.02 7.647a.5.5 0 0 1 0 .707l-2.666 2.666a.5.5 0 0 1-.708-.707l2.667-2.666a.5.5 0 0 1 .707 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.646 4.98a.5.5 0 0 1 .708 0l2.666 2.667a.5.5 0 1 1-.707.707L9.646 5.687a.5.5 0 0 1 0-.707"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgArrowRight;
