import * as React from 'react';
import type { SVGProps } from 'react';
const SvgTickets = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.37 3.37c.344-.343.81-.537 1.297-.537H6a.5.5 0 0 1 0 1H4.667a.833.833 0 0 0-.834.834v8a.833.833 0 0 0 .834.833h6.666a.834.834 0 0 0 .834-.833v-8a.834.834 0 0 0-.834-.834H10a.5.5 0 1 1 0-1h1.333a1.833 1.833 0 0 1 1.834 1.834v8a1.833 1.833 0 0 1-1.834 1.833H4.667a1.833 1.833 0 0 1-1.834-1.833v-8c0-.487.193-.953.537-1.297"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.333 2.5a.833.833 0 0 0 0 1.667h1.334a.833.833 0 1 0 0-1.667zM5.5 3.333c0-1.012.82-1.833 1.833-1.833h1.334a1.833 1.833 0 0 1 0 3.667H7.333A1.833 1.833 0 0 1 5.5 3.333"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgTickets;
