import * as React from 'react';
import type { SVGProps } from 'react';
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 4.5A3.5 3.5 0 0 0 4.5 8v8A3.5 3.5 0 0 0 8 19.5h8a3.5 3.5 0 0 0 3.5-3.5V8A3.5 3.5 0 0 0 16 4.5zM3.5 8A4.5 4.5 0 0 1 8 3.5h8A4.5 4.5 0 0 1 20.5 8v8a4.5 4.5 0 0 1-4.5 4.5H8A4.5 4.5 0 0 1 3.5 16z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 9.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M8.5 12a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0m8-5a.5.5 0 1 1 0 1 .5.5 0 0 1 0-1"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgInstagram;
