import { styled } from '@styles/stitches.config';

export const ListItem = styled('li', {
  marginLeft: '1.25rem',

  ['> *::before']: {
    content: '∙',
    position: 'absolute',
    height: '100%',
    color: '$black',
    marginLeft: '-1.65rem',
  },
});
