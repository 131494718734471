import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPencil = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.384 12.616h2.249l5.901-5.902a1.59 1.59 0 0 0-2.248-2.248l-5.902 5.901zm5.34-7.588 2.248 2.248"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.933 4.112a2.09 2.09 0 0 1 2.955 2.955L5.986 12.97a.5.5 0 0 1-.353.147H3.384a.5.5 0 0 1-.5-.5v-2.249a.5.5 0 0 1 .147-.353l5.902-5.902zm1.477.388c-.289 0-.566.115-.77.32l-5.756 5.754v1.542h1.542L11.18 6.36a1.09 1.09 0 0 0-.77-1.86"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.37 4.674a.5.5 0 0 1 .708 0l2.248 2.249a.5.5 0 0 1-.707.707L8.37 5.38a.5.5 0 0 1 0-.707z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPencil;
