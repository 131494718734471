import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEye = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 7.167a.833.833 0 1 0 0 1.666.833.833 0 0 0 0-1.666M6.167 8a1.833 1.833 0 1 1 3.666 0 1.833 1.833 0 0 1-3.666 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.912 8C3.607 10.845 5.641 12.167 8 12.167S12.393 10.845 14.088 8C12.393 5.155 10.359 3.833 8 3.833S3.607 5.155 1.912 8M.9 7.752C2.731 4.546 5.093 2.834 8 2.834s5.268 1.712 7.1 4.918a.5.5 0 0 1 0 .496c-1.832 3.206-4.193 4.919-7.1 4.919S2.731 11.454.9 8.248a.5.5 0 0 1 0-.496"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEye;
