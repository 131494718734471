import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPowerpoint = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 22" width="1em" height="1em" {...props}>
    <path
      fill="#B50600"
      fillRule="evenodd"
      d="M1.044 2.326A1.25 1.25 0 0 0 0 3.56v15.382a1.25 1.25 0 0 0 1.044 1.233l12.5 2.084A1.25 1.25 0 0 0 15 21.025V1.475A1.25 1.25 0 0 0 13.544.244zM7.37 16.337l-3.462.062-.017-.975 1.1-.02-.124-6.886-1.1.02-.017-.962 4.112-.074c.537-.01 1.075.018 1.516.21.92.371 1.524 1.298 1.545 2.473.022 1.237-.61 2.249-1.616 2.617-.41.157-.834.202-1.321.211l-1.788.032.042 2.337 1.113-.02zM6.118 8.558l.062 3.462 1.637-.029c.4-.007.711-.075.984-.193.558-.26.873-.828.86-1.59-.013-.713-.31-1.232-.815-1.486-.277-.145-.653-.2-1.078-.193z"
      clipRule="evenodd"
    />
    <path
      fill="#B50600"
      fillRule="evenodd"
      d="M16.24 21.25h7.51c.69 0 1.25-.56 1.25-1.25V3.75c0-.69-.56-1.25-1.25-1.25h-7.5v2.922A4.4 4.4 0 0 1 18.125 5v4.375H22.5a4.375 4.375 0 0 1-6.25 3.953V15h5v1.25h-5V17h5v1.25h-5v2.774q0 .115-.01.226M15 17h-2.5v1.25H15v2.774q0 .117-.02.226h-3.73V2.5H15v3.813a4.376 4.376 0 0 0 0 6.124V15h-2.5v1.25H15zm8.75-8.875a4.376 4.376 0 0 0-4.375-4.375v4.375z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPowerpoint;
