import * as React from 'react';
import type { SVGProps } from 'react';
const SvgMapPointer = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 5.833a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.5 1.5a2.5 2.5 0 1 1 5 0 2.5 2.5 0 0 1-5 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a4.833 4.833 0 0 0-3.418 8.25l2.83 2.83a.833.833 0 0 0 1.177 0l2.829-2.83A4.833 4.833 0 0 0 8 2.5m4.125 8.958a5.833 5.833 0 1 0-8.25 0l2.83 2.829a1.834 1.834 0 0 0 2.591 0z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMapPointer;
