import * as React from 'react';
import type { SVGProps } from 'react';
const SvgOptions = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m0 4.667A.667.667 0 1 0 8 12a.667.667 0 0 0 0 1.333M8 4a.667.667 0 1 0 0-1.334A.667.667 0 0 0 8 4"
    />
    <path
      fill="currentColor"
      d="M8 8.666a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333m0 4.667A.667.667 0 1 0 8 12a.667.667 0 0 0 0 1.333M8 4a.667.667 0 1 0 0-1.334A.667.667 0 0 0 8 4"
    />
  </svg>
);
export default SvgOptions;
