import * as React from 'react';
import type { SVGProps } from 'react';
const SvgEyeOff = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M1.646 1.646a.5.5 0 0 1 .708 0l12 12a.5.5 0 0 1-.708.708l-12-12a.5.5 0 0 1 0-.708"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.41 6.705a.5.5 0 0 1 0 .707.834.834 0 0 0 1.178 1.179.5.5 0 0 1 .707.707 1.834 1.834 0 0 1-2.592-2.594.5.5 0 0 1 .707 0z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.252 3.879 5.722 5.715A12.6 12.6 0 0 0 14.088 8C12.393 5.155 10.359 3.833 8 3.833h-.002q-.375 0-.746.046M8 2.833a6.8 6.8 0 0 0-1.897.263.5.5 0 0 0-.215.834l6.756 6.749a.5.5 0 0 0 .72-.014Q14.3 9.65 15.1 8.248a.5.5 0 0 0 0-.496C13.269 4.546 10.907 2.834 8 2.833M4.835 4.16a.5.5 0 0 1-.133.694C3.694 5.537 2.761 6.577 1.912 8 3.607 10.845 5.641 12.167 8 12.167a5.75 5.75 0 0 0 3.292-1.016.5.5 0 1 1 .559.83A6.75 6.75 0 0 1 8 13.166c-2.907 0-5.269-1.713-7.1-4.919a.5.5 0 0 1 0-.496c.938-1.642 2.014-2.895 3.24-3.726a.5.5 0 0 1 .695.133z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgEyeOff;
