import * as React from 'react';
import { styled, CSS } from '@styles/stitches.config';
import { Loader } from './loader';
import { transitionColors } from '@utils/animations';

type ButtonProps = React.ComponentProps<typeof StyledButton> & {
  loading?: boolean;
};

export function Button({ type = 'button', disabled, children, variant, loading, ...props }: ButtonProps): JSX.Element {
  return (
    <StyledButton type={type} disabled={disabled || loading} variant={disabled ? 'disabled' : variant} isLoading={loading} {...props}>
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <ButtonLabel>{children}</ButtonLabel>
    </StyledButton>
  );
}

export const AnchorButton = React.forwardRef<HTMLAnchorElement, any>(({ children, ...props }, ref) => {
  return (
    <StyledBaseButton ref={ref} {...props}>
      {children}
    </StyledBaseButton>
  );
});

AnchorButton.displayName = 'AnchorButton';

const ButtonLabel = styled('span', {
  fontSize: '$sm',
});

const sharedButtonStyles: CSS = {
  $$borderWidth: '1px',
  position: 'relative',
  display: 'inline-block',
  borderRadius: '$1',
  padding: 'calc($4 - $$borderWidth)',
  lineHeight: 1.5,
  letterSpacing: '2px',
  border: '$$borderWidth solid transparent',
  fontWeight: 'bold',
  ring: true,
};

const StyledBaseButton = styled('a', {
  ...sharedButtonStyles,
  ...transitionColors,
  variants: {
    variant: {
      primary: {
        color: '$gray50',
        background: '$primary400',
        $$ringBoxShadow: '$shadows$1',
        '&:hover': {
          background: '$primary50',
          color: '$primary400',
        },
        '&:active': {
          background: '$primary100',
          color: '$gray50',
        },
      },
      secondary: {
        color: '$primary400',
        borderColor: '$primary400',
        background: '$gray50',
        $$ringColor: '$colors$tertiary400',
        '&:hover': {
          background: '$primary50',
        },
        '&:active': {
          borderColor: 'transparent',
        },
        '&:focus-visible': {
          borderColor: 'transparent',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

// TODO: Refactor with StyledBaseButton as starting point and add a loading and disabled variant when 0.2.x is working correctly on Safari.
const StyledButton = styled('button', {
  ...sharedButtonStyles,
  ...transitionColors,
  variants: {
    variant: {
      primary: {
        color: '$gray50',
        background: '$primary400',
        $$ringBoxShadow: '$shadows$1',
        '&:hover': {
          background: '$primary50',
          color: '$primary400',
        },
        '&:active': {
          background: '$primary100',
          color: '$gray50',
        },
      },
      secondary: {
        color: '$primary400',
        borderColor: '$primary400',
        background: '$gray50',
        $$ringColor: '$colors$tertiary400',
        '&:hover': {
          background: '$primary50',
        },
        '&:active': {
          borderColor: 'transparent',
        },
        '&:focus-visible': {
          borderColor: 'transparent',
        },
      },
      disabled: {
        color: '$gray400',
        background: '$gray100',
        ring: true,
        $$ringBoxShadow: '$shadows$1',
        cursor: 'not-allowed',
      },
    },
    isLoading: {
      true: {
        cursor: 'progress',
        [`& ${ButtonLabel}`]: {
          visibility: 'hidden',
        },
      },
    },
  },
  defaultVariants: {
    variant: 'primary',
  },
});

const LoaderContainer = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});
