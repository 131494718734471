import * as React from 'react';
import type { SVGProps } from 'react';
const SvgHelpcenter = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 5.833a2.167 2.167 0 1 0 0 4.334 2.167 2.167 0 0 0 0-4.334M4.833 8a3.167 3.167 0 1 1 6.334 0 3.167 3.167 0 0 1-6.334 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 2.5a5.5 5.5 0 1 0 0 11 5.5 5.5 0 0 0 0-11M1.5 8a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.646 9.646a.5.5 0 0 1 .708 0l2.233 2.234a.5.5 0 0 1-.707.707l-2.234-2.233a.5.5 0 0 1 0-.708m-3.292 0a.5.5 0 0 1 0 .708L4.12 12.587a.5.5 0 0 1-.707-.707l2.233-2.234a.5.5 0 0 1 .708 0M3.413 3.413a.5.5 0 0 1 .707 0l2.234 2.233a.5.5 0 0 1-.708.708L3.413 4.12a.5.5 0 0 1 0-.707m9.174 0a.5.5 0 0 1 0 .707l-2.233 2.234a.5.5 0 0 1-.708-.708l2.234-2.233a.5.5 0 0 1 .707 0"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgHelpcenter;
