import * as React from 'react';
import type { SVGProps } from 'react';
const SvgBook = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5 12.363a5.5 5.5 0 0 0-2.75.737.5.5 0 0 1-.5-.866A6.5 6.5 0 0 1 8 12.097a6.5 6.5 0 0 1 6.25.137.5.5 0 1 1-.5.866 5.5 5.5 0 0 0-5.5 0 .5.5 0 0 1-.5 0A5.5 5.5 0 0 0 5 12.363m0-8.667a5.5 5.5 0 0 0-2.75.737.5.5 0 0 1-.5-.866A6.5 6.5 0 0 1 8 3.43a6.5 6.5 0 0 1 6.25.137.5.5 0 1 1-.5.866 5.5 5.5 0 0 0-5.5 0 .5.5 0 0 1-.5 0A5.5 5.5 0 0 0 5 3.696"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 3.5a.5.5 0 0 1 .5.5v8.667a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m6 0a.5.5 0 0 1 .5.5v8.667a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5m6 0a.5.5 0 0 1 .5.5v8.667a.5.5 0 1 1-1 0V4a.5.5 0 0 1 .5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgBook;
