import * as React from 'react';
import type { SVGProps } from 'react';
const SvgPdf = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 22" width="1em" height="1em" {...props}>
    <path
      fill="#B50600"
      fillRule="evenodd"
      d="M3.917 1.833c-.504 0-.99.204-1.35.57A2 2 0 0 0 2 3.798v14.404c0 .526.205 1.027.567 1.395.36.366.846.57 1.35.57h9.916c.503 0 .99-.204 1.35-.57a2 2 0 0 0 .567-1.395V9.167h-5.133a2.2 2.2 0 0 1-2.2-2.2V1.833zm6.333 1.069 4.358 4.431h-3.992a.367.367 0 0 1-.366-.366zM1.26 1.118A3.73 3.73 0 0 1 3.916 0h5.666a.92.92 0 0 1 .654.274l7.083 7.202a.92.92 0 0 1 .263.643v10.083a3.82 3.82 0 0 1-1.093 2.68A3.73 3.73 0 0 1 13.833 22H3.917c-1 0-1.955-.404-2.658-1.118a3.82 3.82 0 0 1-1.092-2.68V3.798c0-1.003.39-1.967 1.092-2.68Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      d="M2.916 17.417H4.64v-.506h-.554v-1.214h.89c.243 0 .454-.02.66-.098.504-.181.828-.7.828-1.344 0-.61-.293-1.097-.747-1.298-.218-.104-.486-.123-.753-.123H2.916v.5h.548v3.577h-.548zm1.17-2.253v-1.798h.822c.211 0 .398.033.535.11.249.137.392.41.392.78 0 .395-.162.688-.442.817a1.2 1.2 0 0 1-.492.091zm2.896 2.253h1.88c.348 0 .64-.026.927-.123.859-.286 1.388-1.059 1.388-2.169 0-1.116-.548-1.908-1.388-2.181-.293-.09-.566-.11-.934-.11H6.982v.5h.548v3.577h-.548zm1.17-.532v-3.519h.672c.287 0 .51.013.753.104.585.22.953.811.953 1.655 0 .838-.368 1.429-.959 1.643-.224.09-.46.117-.747.117zm3.638.532h1.767v-.506h-.597v-1.468h1.518v-.532H12.96v-1.519h1.331v.526h.542v-1.084H11.79v.5h.547v3.577h-.547z"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.687 12.604h2.277c.27 0 .581.018.848.145.552.245.88.825.88 1.506 0 .716-.363 1.337-.977 1.56a2 2 0 0 1-.739.11h-.66v.756h.553v.965H2.687v-.965h.548v-3.118h-.548zm.458.459v.041h.548v4.036h-.548v.048h1.266v-.048h-.554v-1.673h1.12c.23 0 .41-.019.578-.082l.003-.001c.394-.142.676-.56.676-1.129 0-.538-.255-.931-.61-1.089l-.006-.002c-.168-.08-.39-.101-.654-.101zm3.608-.459h2.102c.375 0 .676.02 1.002.121l.003.001c.952.31 1.546 1.204 1.546 2.4 0 1.19-.576 2.062-1.544 2.385-.32.109-.642.135-1 .135H6.753v-.965h.548v-3.118h-.548zm.458.459v.041h.548v4.036h-.548v.048h1.65c.339 0 .602-.026.854-.111h.002c.748-.25 1.23-.922 1.23-1.952 0-1.036-.5-1.726-1.227-1.963-.26-.08-.506-.1-.865-.1H7.211Zm4.35-.459h3.501v1.543h-1v-.526h-.873v1.06h1.518v.992H13.19v1.008h.597v.965H11.56v-.965h.548v-3.118h-.548zm.458.459v.041h.547v4.036h-.547v.048h1.309v-.048h-.598v-1.926h1.519v-.074H12.73v-1.977h1.79v.526h.084v-.626zm-8.162.074h1.05c.229 0 .461.034.648.14.34.186.51.55.51.978 0 .459-.193.849-.575 1.026l-.007.003c-.169.072-.356.11-.582.11H3.857zm.458.458v1.34h.586a1 1 0 0 0 .399-.071c.176-.083.306-.277.306-.609 0-.31-.117-.492-.273-.578l-.003-.001c-.087-.05-.228-.08-.422-.08zm3.608-.458h.901c.292 0 .55.012.834.118.698.264 1.1.96 1.1 1.87 0 .903-.4 1.6-1.105 1.857-.262.105-.531.132-.829.132h-.901zm.458.458v3.06h.443c.274 0 .477-.025.661-.1l.008-.003c.475-.172.807-.656.807-1.427 0-.777-.332-1.263-.803-1.44-.203-.076-.392-.09-.673-.09z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPdf;
