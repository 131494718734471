import * as React from 'react';
import { styled } from '@styles/stitches.config';
import { Text } from './text';

const DEFAULT_TAG = 'h2';

interface HeadingProps extends React.ComponentProps<typeof StyledHeading> {
  as?: React.ElementType;
}

export const Heading = React.forwardRef<React.ElementRef<typeof DEFAULT_TAG>, HeadingProps>(function Heading(props, forwardedRef) {
  return <StyledHeading as={DEFAULT_TAG} {...props} ref={forwardedRef} />;
});

Heading.toString = () => `.${StyledHeading.className}`;

const StyledHeading = styled(Text, {
  fontFamily: '$heading',
  fontWeight: 400,
  color: '$primary400',
  variants: {
    variant: {
      h1: {
        fontSize: '$3xl',
        '@md': {
          fontSize: '$4xl',
        },
        '@lg': {
          fontSize: '$5xl',
        },
        '@xl': {
          fontSize: '$6xl',
        },
      },
      h2: {
        fontSize: '$xl',
        '@md': {
          fontSize: '$2xl',
        },
        '@lg': {
          fontSize: '$3xl',
        },
        '@xl': {
          fontSize: '$4xl',
        },
      },
      h3: {
        fontSize: '$base',
        '@md': {
          fontSize: '$lg',
        },
        '@lg': {
          fontSize: '$xl',
        },
        '@xl': {
          fontSize: '$2xl',
        },
      },
      h4: {
        fontSize: '$sm',
        lineHeight: '120%',
        '@md': {
          fontSize: '$base',
        },
        '@lg': {
          fontSize: '$lg',
        },
        '@xl': {
          fontSize: '$xl',
        },
      },
      h5: {
        fontSize: '$xs',
        '@md': {
          fontSize: '$sm',
        },
        '@lg': {
          fontSize: '$md',
        },
        '@xl': {
          fontSize: '$lg',
        },
      },
      h6: {
        fontSize: '$xxs',
        '@md': {
          fontSize: '$xxs',
        },
        '@lg': {
          fontSize: '$xxs',
        },
        '@xl': {
          fontSize: '$xs',
        },
      },
    },
  },
});
