import * as React from 'react';
import type { SVGProps } from 'react';
const SvgChat = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.667 3.167a1.5 1.5 0 0 0-1.5 1.5v4a1.5 1.5 0 0 0 1.5 1.5H6a.5.5 0 0 1 .354.146L8 11.959l1.646-1.646a.5.5 0 0 1 .354-.147h1.333a1.5 1.5 0 0 0 1.5-1.5v-4a1.5 1.5 0 0 0-1.5-1.5H4.667zm-1.768-.268a2.5 2.5 0 0 1 1.768-.732h6.666a2.5 2.5 0 0 1 2.5 2.5v4a2.5 2.5 0 0 1-2.5 2.5h-1.126L8.354 13.02a.5.5 0 0 1-.708 0l-1.853-1.854H4.667a2.5 2.5 0 0 1-2.5-2.5v-4A2.5 2.5 0 0 1 2.899 2.9z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.833 5.333a.5.5 0 0 1 .5-.5h5.334a.5.5 0 0 1 0 1H5.333a.5.5 0 0 1-.5-.5m0 2.667a.5.5 0 0 1 .5-.5h4a.5.5 0 1 1 0 1h-4a.5.5 0 0 1-.5-.5"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgChat;
