import * as React from 'react';
import type { SVGProps } from 'react';
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7 5.5A3.5 3.5 0 0 0 3.5 9v6A3.5 3.5 0 0 0 7 18.5h10a3.5 3.5 0 0 0 3.5-3.5V9A3.5 3.5 0 0 0 17 5.5zM2.5 9A4.5 4.5 0 0 1 7 4.5h10A4.5 4.5 0 0 1 21.5 9v6a4.5 4.5 0 0 1-4.5 4.5H7A4.5 4.5 0 0 1 2.5 15z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.754 8.565a.5.5 0 0 1 .503.006l5 3a.5.5 0 0 1 0 .858l-5 3A.5.5 0 0 1 9.5 15V9a.5.5 0 0 1 .254-.435m.746 1.318v4.234L14.028 12z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgYoutube;
